<template>
	<div class="d-flex justify-content-center flex-column align-items-center">
			<h5>{{$t('unsubscribe.title')}}</h5>

			<div v-if="submitting">
				<LoadingSpinner />
			</div>
			<div v-else>
				<div>
					<button class="btn btn-primary" @click="unsub">{{$t('unsubscribe.unsubscribe')}}</button>
				</div>
			</div>
	</div>
</template>

<script>

import LoadingSpinner from "@/components/LoadingSpinner.vue";
import Question from "@/components/questionnaire/Question.vue";

export default {
	name: 'UnsubscribeView',
	components: { LoadingSpinner },
	computed: {
	},
	data() {
		return {
			types: [],
			submitting: false,
		}
	},
	watch: {

	},
	props: {
		msgs: Object
	},
	methods: {
		async unsub() {
			this.submitting = true;
			await this.$rest.unsubscribe(this.$route.query.token, this.types);
			this.submitting = false;
		},
	},
	created() {
			this.types = this.$route.query.type.split(';');

	}
}

</script>

<style scoped>


</style>
